<template>
  <div class="park">
    <div class="park-content">
      <router-view></router-view>
      <!-- <component :is="currentComp"></component> -->
    </div>
  </div>
</template>

<script>
// import schoolInfo from "./schoolInfo.vue";
// import schoolPark from "./schoolPark.vue";
export default {
  data() {
    return {
      gradeId: "",
      currentComp: "schoolInfo",
      isActive: "1",
      menu: [
        {
          name: "学校信息",
          value: "1",
          comp: "schoolInfo",
        },
        {
          name: "校园管理",
          value: "2",
          comp: "schoolPark",
        },
      ],
    };
  },
  mounted() {
    this.getClassInfo();
  },
  methods: {
    // V2.0 获取学校列表
    getClassInfo() {
      this.api.school
        .getSchoolGradeList(this.$store.state.userInfo.schoolId)
        .then((res) => {
          console.log(res);
          if (res.flag) {
            this.gradeId = res.data.defaultClass; // 默认展示学校ID
          }
        });
    },
  },
  components: {
    // schoolInfo,
    // schoolPark,
  },
};
</script>
<style lang="scss" scoped>
.park {
  // white-space: nowrap;
  // display: inline-block;
  width: 1434px;
  margin: 0 auto;
}
.park-rou {
  background: #ffffff;
  height: 58px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  > ul {
    width: 1434px;
    margin: 0 auto;
    padding-top: 19px;

    li {
      margin: 0 65px;
      display: inline-block;
      width: 108px;
      font-size: 24px;
      font-weight: normal;
      color: #333333;
      text-align: center;
      cursor: pointer;
      a {
        display: block;
      }
    }
    .router-link-active {
      color: #ba3037;
      border-bottom: 4px solid #ba3037;
      font-weight: bold;
    }
  }
}
.park-content {
  // margin-top: 40px;
}
</style>